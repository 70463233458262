import React from "react";

export default function Information(props) {
  return (
    <>
      <div className="body-container">
        <div className="info-body">
          <h2 className="title-page">Contact Us To Get More Info</h2>
          <div className="container position-absolute top-50 start-50 translate-middle">
            <a
              className={props.linkEmail}
              href="mailto:manhattangoldcoin@protonmail.com"
            >
              <h4>ManhattanGoldCoin Email</h4>
            </a>
            <a
              className={props.linkEmail}
              href="mailto:manhattangoldcoin@protonmail.com"
            >
              <h4 className="email-h4">manhattangoldcoin@protonmail.com</h4>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
