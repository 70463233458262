import React, { useEffect, useState } from "react";
import ImageHome from "../imgs/ny-stock.jpeg";
import MinerIcon from "../imgs/miner.png";
import PionerIcon from "../imgs/pioner.png";
import WalletIcon from "../imgs/download.png";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import "../App.css";
export default function Home({ animateIcon, setAnimateIcon, openNavbar }) {
  //const [showText, setShowText] = useState("text-element");
  //const [showText2, setShowText2] = useState("text-element");
  const [showText1, setShowText1] = useState("text-element");
  //const [hideText, setHideText] = useState("hidden-text");
  //const [hideText2, setHideText2] = useState("hidden-text");
  const [hideText1, setHideText1] = useState("hidden-text");
  const [animationIcon, setAnimationIcon] = useState("c-scroll-icon");

  /*const hiddenTextHandle = () => {
    if (showText === "hidden-text") {
      setShowText("text-element");
      setHideText("hidden-text");
    } else {
      setShowText("hidden-text");
      setHideText("text-element");
    }
  };*/

  useEffect(() => {
    setAnimationIcon(animateIcon);
  }, [animateIcon]);
  const hiddenTextHandle1 = () => {
    if (showText1 === "hidden-text") {
      setShowText1("text-element");
      setHideText1("hidden-text");
    } else {
      setShowText1("hidden-text");
      setHideText1("text-element");
    }
  };
  const homeImageScroll = () => {
    const element = document.getElementById("image-mob");
    const elementDesktop = document.getElementById("image-desktop");
    const imagePosition = element.getBoundingClientRect().top;
    const desktopPosition = elementDesktop.getBoundingClientRect().top;
    if (
      openNavbar === "links-list-opened" ||
      imagePosition < -400 ||
      desktopPosition < -400
    ) {
      setAnimateIcon("c-scroll-icon-none");
    } else if (openNavbar === "links-list" && imagePosition >= -400) {
      setAnimateIcon("c-scroll-icon");
    } else if (openNavbar === "links-list" && desktopPosition >= -400) {
      setAnimateIcon("c-scroll-icon");
    }
  };
  window.addEventListener("scroll", homeImageScroll);

  /*const showTextHandle = () => {
    if (showText2 === "hidden-text") {
      setShowText2("text-element");
      setHideText2("hidden-text");
    } else {
      setShowText2("hidden-text");
      setHideText2("text-element");
    }
  };*/
  return (
    <>
      <div className="body-container-home">
        <img
          className="image-home"
          src={ImageHome}
          alt="Banner Home"
          id="image-desktop"
        />
        <img className="image-home-mob" id="image-mob" />
        <div className={animationIcon}>
          <div className="c-scroll-icon-line-mask">
            <div className="c-scroll-icon-line"></div>
          </div>
          <div className="c-scroll-icon-triangle">
            <div className="c-scroll-icon-triangle-mask first">
              <div className="c-scroll-icon-triangle-line first"></div>
            </div>
            <div className="c-scroll-icon-triangle-mask right">
              <div className="c-scroll-icon-triangle-line right"></div>
            </div>
            <div className="c-scroll-icon-triangle-mask left">
              <div className="c-scroll-icon-triangle-line left"></div>
            </div>
            <div className="c-scroll-icon-triangle-mask last">
              <div className="c-scroll-icon-triangle-line last"></div>
            </div>
          </div>
        </div>
        <div className="section-text">
          <div className="section-title">
            <h1>Learn About Manhattan Gold Coin</h1>
          </div>
          <div className="first-item" id="first-item-component">
            <div className="block-component">
              <div className="icon-component">
                <img className="icon" src={MinerIcon} alt="Support" />
              </div>
              <div className="text-component">
                <h3 className="text-title">PROOF OF WORK</h3>
                <p className={showText1}>
                  A decentralized consensus mechanism that requires members of a
                  network to expend effort solving an arbitrary mathematical
                  <button
                    className="text-btn"
                    onClick={() => hiddenTextHandle1()}
                  >
                    Read more...
                  </button>
                </p>
                <p className={hideText1}>
                  A decentralized consensus mechanism that requires members of a
                  network to expend effort solving an arbitrary mathematical
                  puzzle to prevent anybody from gaming the system. Proof of
                  work is used widely in cryptocurrency mining, for validating
                  transactions and mining new tokens.
                  <button
                    className="text-btn"
                    onClick={() => hiddenTextHandle1()}
                  >
                    Hide Text...
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div className="second-item">
            <div className="block-component">
              <div className="text-component">
                <h3 className="text-title">SUPPORT MGC COIN</h3>
                <p className="text-element">
                  Be one of the Pioneers of our blockchain. Watch it change.
                </p>
              </div>
              <div className="icon-component">
                <img className="icon" src={PionerIcon} alt="Proof of Work" />
              </div>
            </div>
          </div>
          <div className="first-item">
            <div className="block-component">
              <div className="icon-component">
                <img className="icon" src={WalletIcon} alt="MGC Wallet" />
              </div>
              <div className="text-component">
                <h3 className="text-title">DOWNLOAD MGC COIN WALLET</h3>

                <p className="text-element">
                  Download your wallet, Cause if YOU don’t have the keys It is
                  not your bitcoin !!!! Or manhattangoldcoin !!!!!
                  <CustomLink to="downloads" className="text-btn">
                    Download...
                  </CustomLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
