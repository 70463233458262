import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import WindowsLogo from "../imgs/windows.png";
import AppleLogo from "../imgs/apple.png";
import AppleM1 from "../imgs/applem1.png";
import UbuntuLogo from "../imgs/ubuntu.png";
import MGCWindowWallet from "../wallets/manhattangoldcoin-1.0.0-win64-setup.zip";
import MGCUbuntuWallet from "../wallets/mgccoin.tar.gz";
import MGCMacWallet from "../wallets/Manhattangoldcoin-Qt.dmg";

export default function Downloads() {
  return (
    <>
      <div className="body-container">
        <div className="titles-download">
          <h2 className="title-page">WELCOME TO MANHATTANGOLDCOIN WALLET</h2>
          <h6 className="title-page">
            The open source wallet for ManhattanGoldCoin, built to grow with the
            litecoin network.
          </h6>
        </div>
        <Row xs={1} lg={1} className="cards-download g-4">
          <Col>
            <a className="download-link" href={MGCWindowWallet} download>
              <Card className="card-box">
                <Card.Img
                  variant="top"
                  className="card-img"
                  src={WindowsLogo}
                />
                <Card.Body>
                  <h2 className="card-title">Windows.</h2>
                  <Button
                    href={MGCWindowWallet}
                    className="btn-slideshow"
                    size="lg"
                    variant="outline-light"
                  >
                    DOWNLOAD
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a className="download-link" href={MGCMacWallet} download>
              <Card className="card-box">
                <Card.Img variant="top" className="card-img" src={AppleLogo} />
                <Card.Body>
                  <h2 className="card-title">Apple with Intel.</h2>
                  <Button
                    href={MGCMacWallet}
                    className="btn-slideshow"
                    size="lg"
                    variant="outline-light"
                  >
                    DOWNLOAD
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a className="download-link" href={MGCMacWallet} download>
              <Card className="card-box">
                <Card.Img
                  variant="top"
                  className="card-img-smll"
                  src={AppleM1}
                />
                <Card.Body>
                  <h2 className="card-title">Apple with M1.</h2>
                  <Button
                    href={MGCMacWallet}
                    className="btn-slideshow"
                    size="lg"
                    variant="outline-light"
                  >
                    DOWNLOAD
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col>
            <a className="download-link" href={MGCUbuntuWallet} download>
              <Card className="card-box">
                <Card.Img
                  variant="top"
                  className="card-img-smll"
                  src={UbuntuLogo}
                />
                <Card.Body>
                  <h2 className="card-title">Ubuntu.</h2>
                  <Button
                    href={MGCUbuntuWallet}
                    className="btn-slideshow"
                    size="lg"
                    variant="outline-light"
                  >
                    DOWNLOAD
                  </Button>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>

        <h2 className="terms-service-tittle">
          Guild to setup ManhattanGoldCoin Core
        </h2>
        <ol className="instructions-list">
          <li>Open your terminal</li>
          <li>sudo su</li>
          <li>ls</li>
          <li>unzip mgtcoin.zip</li>
          <li>cd mgtcoin/</li>
          <li>chmod +x ./autogen.sh</li>
          <li>chmod +x share/genbuild.sh</li>
          <li>
            sudo apt-get install build-essential libtool autotools-dev automake
            pkg-config bsdmainutils python3
          </li>
          <li>
            sudo apt-get install libssl-dev libevent-dev libboost-system-dev
            libboost-filesystem-dev libboost-chrono-dev libboost-test-dev
            libboost-thread-dev
          </li>
          <li>sudo apt-get install libminiupnpc-dev</li>
          <li>sudo apt-get install libzmq3-dev</li>
          <li>
            sudo apt-get install libqt5gui5 libqt5core5a libqt5dbus5
            qttools5-dev qttools5-dev-tools libprotobuf-dev protobuf-compiler
          </li>
          <li>sudo apt-get install libqrencode-dev</li>
          <li>sudo apt update</li>
          <li>sudo apt install dos2unix</li>
          <li>find . -name \*.m4|xargs dos2unix</li>
          <li>find . -name \*.ac|xargs dos2unix</li>
          <li>find . -name \*.am|xargs dos2unix</li>
          <li>find . -name \*.sh|xargs dos2unix</li>
          <li>./autogen.sh</li>
          <li>./configure --disable-tests</li>
          <li>chmod +x contrib/install_db4.sh</li>
          <li>./contrib/install_db4.sh `pwd`</li>
          <li>./configure --disable-tests</li>
          <li>export BDB_PREFIX='/home/user/mgtcoin/db4'</li>
          <li>
            ./configure BDB_LIBS="-L$&#123;BDB_PREFIX&#125;/lib -ldb_cxx-4.8"
            BDB_CFLAGS="-I$&#123;BDB_PREFIX&#125;/include" --disable-tests
          </li>
          <li>make</li>
          <li>make install</li>
        </ol>
      </div>
    </>
  );
}
