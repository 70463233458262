import React from "react";
import "../App.css";
import WhitePaperFile from "../imgs/Whitepaper Manhattan Gold Coin.pdf";

export default function WhitePaper() {
  return (
    <div className="whitepaper-comp">
      <iframe
        title="Whitepaper Manhattan Gold Coin"
        className="pdf-comp"
        src={WhitePaperFile}
        type="application/pdf"></iframe>
    </div>
  );
}
