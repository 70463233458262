import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Information from "./pages/Information";
import TermsService from "./pages/TermService";
import Downloads from "./pages/Downloads";
import WhitePaper from "./pages/WhitePaper";

function App() {
  const [footerStyle, setFooterStyle] = useState("footer-component");
  const [linkEmail, setLinkEmail] = useState("email-link");
  const [txtEmail, setTxtEmail] = useState("email-link");
  const [animateIcon, setAnimateIcon] = useState("c-scroll-icon");
  const [openNavbar, setOpenNavbar] = useState("links-list");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [footerStyle]);

  return (
    <>
      <Navbar
        setFooterStyle={setFooterStyle}
        setLinkEmail={setLinkEmail}
        setTxtEmail={setTxtEmail}
        setAnimateIcon={setAnimateIcon}
        openNavbar={openNavbar}
        setOpenNavbar={setOpenNavbar}
      />
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setAnimateIcon={setAnimateIcon}
                animateIcon={animateIcon}
                openNavbar={openNavbar}
              />
            }
          />
          <Route path="/whitepaper" element={<WhitePaper />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route
            path="/information"
            element={<Information linkEmail={linkEmail} txtEmail={txtEmail} />}
          />
          <Route path="/terms-services" element={<TermsService />} />
        </Routes>
      </div>
      <Footer footerStyle={footerStyle} />
    </>
  );
}

export default App;
